export default {
  items: [
    {
      name: "Профиль",
      url: "/system",
      // icon: 'icon-speedometer',
      icon: "fa fa-id-card",
      groups: ["member", "gamemaster", "admin"]
    },
    {
      name: "Мероприятия",
      url: "/system/events",
      icon: "fa fa-calendar-check-o",
      groups: ["member", "gamemaster", "admin"]
    },
    {
      name: "Заявки",
      url: "/system/admin/requests",
      icon: "fa fa-user-plus",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"]
    },
    {
      name: "Участники",
      url: "/system/admin/members",
      icon: "fa fa-users",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"]
    },
    {
      name: "Регистрация",
      url: "/system/admin/register",
      icon: "fa fa-users",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"],
      badge: {
        variant: "primary",
        text: "NEW"
      }
    },
    {
      name: "Оповещения",
      url: "/system/admin/notifications",
      icon: "fa fa-exclamation",
      groups: ["gamemaster", "admin"],
      // badge: {
      //   variant: "success",
      //   text: 2
      // }
    },

    {
      name: "Сообщения",
      url: "/system/admin/messages",
      icon: "fa fa-comments",
      groups: ["gamemaster", "admin"],
    },
    {
      name: "Персонал",
      url: "/system/admin/staff",
      icon: "fa fa-user-secret",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"]
    },
    {
      name: "Предметы",
      url: "/system/admin/items",
      icon: "icon-key",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"]
    },
    {
      name: "Прошивки",
      url: "/system/admin/firmwares",
      icon: "fa fa-microchip",
      variant: "danger",
      groups: ["admin"],
    },
    {
      name: "Локации",
      url: "/system/admin/locations",
      icon: "icon-flag",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"],
    },
    {
      name: "Задания",
      url: "/system/admin/quests",
      icon: "fa fa-book",
      //variant: 'secondary',
      groups: ["gamemaster", "admin"],
    },
    {
      name: "Щогла",
      url: "/system/special/jupiter",
      icon: "fa fa-podcast",
      //variant: 'secondary',
      groups: ["special"],
    }
  ]
};
