<template>
  <div class="app">
    <div v-if="isPageLoading" class="text-center">
      <i class="fa fa-cog fa-spin fa-4x"></i>
    </div>
    <AppHeader fixed v-if="!isPageLoading">
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img
          class="navbar-brand-full"
          src="img/brand/logo.svg"
          width="150"
          height="44"
          alt="stalk.net Logo"
        />
        <img
          class="navbar-brand-minimized"
          src="img/brand/sygnet.svg"
          width="30"
          height="30"
          alt="stalk.net Logo"
        />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="d-md-down-none ml-auto mr-4">
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownAccnt />
        </b-nav-item>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="navItems" role="navigation"/>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid px-2 px-md-4">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside />
      </AppAside>
    </div>
    <TheFooter>
      <a href="https://stalk.net.ua" target="_blank">stalk.net v0.3.3</a> &nbsp;
      by &nbsp;
      <a href="https://prometheus.team" target="_blank">Prometheus Team</a>
      &nbsp; 2021
    </TheFooter>
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
// import API from "../api/stalk.net";

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      navItems: [],
      isPageLoading: false
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    }
  },
  methods: {
    loadData() {
      let self = this;

      //make menu
      self.$store.state.dataLoading.then(() => {
        self.navItems = nav.items.filter(function(value) {
          return value.groups.includes(self.$store.state.user.Group); //localStorage.getItem('user_group'));
        });
      });

      // self.isPageLoading = true;
      // API.private.getSelfUserInfo().then((resp) => {
      //   console.log(resp.data);
      //   return API.private.getMemberInfo(resp.data.Id)
      // }).then((resp) => {
      //   console.log(resp.data);
      // }).catch(e => {
      //   console.error(e);
      //   if ((e.response) && (e.response.data) && (e.response.data.error)) {
      //     console.error(e.response.data);
      //     switch (e.response.data.error) {
      //       case 'ERR_BAD_JWT': case 'ERR_BAD_AUTH': case 'ERR_NOT_ALLOWED': {
      //         self.$router.replace('login');
      //       } break;
      //     }
      //     self.showError({message: e.response});
      //
      //   }
      //   self.isPageLoading = false;
      // })
    }
  },
  mounted: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
