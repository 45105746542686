<template>
  <AppHeaderDropdown right no-caret v-if="!isPageLoading">
    <template slot="header">
      <img
        :src="member.PassportPhoto.Path"
        class="img-avatar"
        :alt="member.User.Email"
      />
    </template>
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>{{ member.User.Email }}</strong>
      </b-dropdown-header>
      <b-dropdown-divider/>
      <b-dropdown-item @click="logout()">
        <i class="fa fa-lock"/>
        Вихід
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
  import {HeaderDropdown as AppHeaderDropdown} from "@coreui/vue";
  import API from "../api/stalk.net";

  export default {
    name: "DefaultHeaderDropdownAccnt",
    components: {
      AppHeaderDropdown
    },
    data() {
      return {
        isPageLoading: false,
        member: {
          PassportPhoto: {
            Path: ""
          },
          User: {
            Email: ""
          }
        },
        baseURL: API.baseURL
      };
    },
    methods: {
      catchError(e) {
        let self = this;
        console.error(e);
        this.showError({message: e.response});
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            self.logout();
            break;
        }
      },
      loadData() {
        let self = this;

        self.isPageLoading = true;
        self.$store.state.dataLoading
          .then(() => {
            self.member = self.$store.state.member.filter(character => character.IsActive)[0]
            self.member.User = self.$store.state.user;

            if (self.member.User.Group === 'special') {
              self.member.PassportPhoto.Path = '/img/avatars/jupiter.png';
            } else {
              self.member.PassportPhoto.Path = self.baseURL + self.member.PassportPhoto.Path;
            }

            self.isPageLoading = false;
          })
          .catch(e => {
            self.catchError(e);
          });
      },
      logout() {
        API.private.logout();
        this.$router.replace("/login");
      }
    },
    async beforeMount() {
      await this.$store.dispatch("loadUser");
      await this.$store.dispatch("loadMember");
      this.loadData();
    },
    notifications: {
      showError: {
        title: "Помилка",
        type: "error"
      }
    }
  };
</script>
